import React, { useEffect, useRef, useState } from 'react'
import { Button, Col, Container, Form, Row } from 'react-bootstrap'
import { MdHistory, MdArrowDownward, MdOutlineRemoveRedEye } from "react-icons/md";
import { downloadAllRecord, downloadRecord, getExcelRecord } from '../api/api';
import { useNavigate, useParams } from 'react-router-dom';
import { dataDownloadAll } from '../helper/helper';
import { useDispatch, useSelector } from 'react-redux';
import { resetSession } from '../store/user/authSlice';
import CustomPagination from '../components/CustomPagination';
import FetchSearchResult from '../components/FetchSearchResult';
import { toast,Bounce } from 'react-toastify';

import moment from 'moment';
import LoaderSpin from '../components/LoaderSpin';


export const FileData = () => {
    // const toastId = useRef(null);   
    // const notify = () => toastId.current = toast.info("Downloading...", {
    //     position: "top-right",
    //     autoClose: 8000,
    //     hideProgressBar: false,
    //     closeOnClick: true,
    //     pauseOnHover: true,
    //     draggable: true,
    //     progress: undefined,
    //     theme: "colored",
    //     transition: Bounce,
    //     });
    // const updateNotify = () => toast.update(toastId.current, { render: "Downloaded", type: toast.TYPE.INFO, autoClose: 5000 });

    const { id } = useParams()
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const authStore = useSelector(state => state.auth)
    const [data, setData] = useState([]);
    const [isDisable, setIsDisable] = useState(true);
    const [fileInfo, setFileInfo] = useState();
    const [loading, setLoading] = useState(false)

    // Pagination

    const [limit, setLimit] = useState(10)
    const [page, setPage] = useState(1);
    const [counts, setCounts] = useState(0);
    const [filter, setFilter] = useState('');

    const fetchExcelRecord = (id) => {
        setLoading(true);
        getExcelRecord(page,limit, filter, authStore.token, id).then((resData)=>{
            // console.log(resData)
            // setTotalPage(Math.ceil(resData.docCount/limit));
            setCounts(resData.data.docCount)
            setData(resData.data.result)
            setFileInfo(resData.data.fileInfo)
            // console.log(Math.ceil(resData.data.docCount / limit))
            setLoading(false);
            
        }).catch((err)=>{
            console.log(err)
            if(err.response.status == 401){
                localStorage.removeItem('auth')
                dispatch(resetSession())
                navigate('/')
            };
            
        })
    }

    const handleView = (path) => {
        navigate(path);
    }

    const handleDownload = (id, owner) =>{
        downloadRecord(id, authStore.token).then((res)=>{
            if (Array.isArray(res?.data) && res.data.length > 0) {
                // const personData = res.data[0].personSearchResponse
                // dataDownload(personData, owner)
                const personData = res.data
                dataDownloadAll(personData, owner)
            } else {
                alert("No data found")
            }
            
        }).catch((err)=>{
            if(err.response.status == 401){
                localStorage.removeItem('auth')
                dispatch(resetSession())
                navigate('/')
            };
            
        })
    }

    const handleDownloadAll = (id) => {
        
        const toastId = toast.info("Downloading...", {
            autoClose: false,
            theme: "colored",
            transition: Bounce,
        });
        downloadAllRecord(page, limit, filter, authStore.token, id).then((res)=>{
            
            console.log(res);
            if (Array.isArray(res?.data) && res.data.length > 0) {
                const personData = res.data;
                dataDownloadAll(personData, 'All')
                
                toast.update(toastId, { render: "Downloaded", autoClose: 5000 });
            } else {
                alert("No data found")
            }
            
        }).catch((err)=>{
            console.log("ERROR: ", err)
            if(err.response.status == 401){
                localStorage.removeItem('auth')
                dispatch(resetSession())
                navigate('/')
            };
            
        })

    }

    useEffect(()=>{
        filter == 'NOT_FOUND'? setIsDisable(true) : setIsDisable(false);
        setPage(1)
    },[filter])

    useEffect(()=>{
        
        if(!id) navigate('/home')
        if(authStore?.token){
            console.log("FETCH: ",authStore?.token)
            fetchExcelRecord(id)
        }
    },[page, authStore?.token, id, filter])



  return (
    <Container className='file-data'>
        {
            fileInfo && 
            <Row className='justify-content-center'>
                <Col lg={3} className='file-info mt-5'>
                    <p className='head'>File Name</p>
                    <h2>{fileInfo.name}</h2>
                    <p><span>Uploaded File :</span> {moment(fileInfo.createdAt).format('D MMM YYYY, hh:ss A')}</p>
                </Col>
            </Row>
            
        }
        

        <Row>
            
                <Col lg={3} className='table-head-left'>
                    <Form.Label>Filter: </Form.Label>
                    <Form.Select onChange={(e)=>setFilter(e.currentTarget.value)}>
                        <option value="">Default</option>
                        <option value="FOUND">Found search records</option>
                        <option value="NOT_FOUND">Not found search record</option>
                    </Form.Select>
                </Col>
                <Col lg={9} className='mt-4 table-head-action'>
                    <Button variant='flat' className='btn-skyblue' onClick={()=>handleDownloadAll(id)} disabled={isDisable}><MdArrowDownward /> Download All</Button>
                    <Button variant='flat' className='btn-orange me-2' onClick={()=>handleView(`/history`)}> <MdHistory /> History</Button>
                    <FetchSearchResult id={id} token={authStore.token} setIsDisable={(val)=>setIsDisable(val)}/>
                </Col>
                
           
            <hr className='mt-3 mb-3'/>

            {loading? <LoaderSpin classes='mt-5 mb-5' /> : <>
            <Col lg={12} className='p-0'>
                <table class="table">
                    <thead>
                    <tr className='th'>
                        <th className='head' scope="col">#</th>
                        <th className='head' scope="col">Address</th>
                        <th className='head' scope="col">City</th>
                        <th className='head' scope="col">State</th>
                        <th className='head' scope="col">Zip code</th>
                        <th className='head' scope="col">Owner Name for Mailing</th>
                        <th className='head' scope="col">Action</th>
                    </tr>
                    </thead>
                    <tbody>
                  

                        {
                           
                            data && data.map( (item, index) => 
                            <tr key={index}>
                                <th scope="row">{(index+1)+(page * limit) - limit}</th>
                                <td>{item.address}</td>
                                <td>{item.city}</td>
                                <td>{item.state}</td>
                                <td>{item.zip_code}</td>
                                <td>{item.owner_name_for_mailing}</td>
                                <td>
                                    <Button  onClick={()=>handleView(`/detail/${item._id}`)} variant='flat' className='btn-darkblue me-2' disabled={isDisable}><MdOutlineRemoveRedEye /> View</Button>
                                    <Button variant='flat' className='btn-skyblue' onClick={()=>handleDownload(item._id, item?.owner_name_for_mailing)} disabled={isDisable}><MdArrowDownward /> Download</Button>
                                </td>
                            </tr>
                            )
                        }
                   
                    </tbody>
                </table>
            </Col>
           
            <CustomPagination limit={limit} counts={counts} page={page} setPage={(val)=>setPage(val)}/>
            </>
            }
        </Row>
        {/* <ToastContainer /> */}
    </Container>
  )
}

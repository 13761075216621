import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import HistoryComp from '../components/HistoryComp'

const History = () => {
  return (
    <Container>
        <Row className='justify-content-center text-center'>
            <Col lg={3} className='file-info mt-5 mb-5'>
                <h2>History</h2>
            </Col>
        </Row>
        <HistoryComp />
    </Container>
  )
}

export default History
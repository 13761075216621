import React, { useEffect, useState } from 'react'
import { dataDownloadAll, flattenObject, flattenObjectOnly, flattenObjectSingle } from '../helper/helper'
import { downloadRecord } from '../api/api';
import { Button, Col, Container, Row } from 'react-bootstrap'
import { useParams, useNavigate } from "react-router-dom";
import { MdArrowDownward } from 'react-icons/md';
import csvDownload from 'json-to-csv-export';
import { useDispatch, useSelector } from 'react-redux';
import { resetSession } from '../store/user/authSlice';

export const Details = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const authStore = useSelector(state => state.auth);

    const [data, setData] = useState([]);
    const { id } = useParams()

    useEffect(()=>{
        downloadRecord(id, authStore.token).then((res)=>{
            if (Array.isArray(res?.data) && res.data[0]?.galaxySearchResponse[0]?.personSearchResponse.length > 0) {
                const personData = res.data[0].galaxySearchResponse[0]?.personSearchResponse
                var keys = ["fullName", "emailAddresses", "phoneNumbers","dob", "age", "indicators", "deathRecords"];
                var result = personData.map(o => Object.assign(...keys.map(k => ({ [k]: o[k] }))));
                const convertedData = flattenObject(result);
                console.log(convertedData);
                setData(convertedData);
            } else {
                //  
                // window.confirm("No data found") ? navigate('/file-data') : navigate('/file-data');
            }
            
        }).catch((err)=>{
            if(err.response.status == 401){
                localStorage.removeItem('auth')
                dispatch(resetSession())
                navigate('/')
            };
            
        })
    },[])

    const handleDownload = (id) =>{
        downloadRecord(id, authStore.token).then((res)=>{
            if (Array.isArray(res?.data) && res.data.length > 0) {
                const personData = res.data
                dataDownloadAll(personData, id)
            } else {
                alert("No data found")
            }
            
        }).catch((err)=>{
            if(err.response.status == 401){
                localStorage.removeItem('auth')
                dispatch(resetSession())
                navigate('/')
            };
            
        })
    }


    // function dataDownload(personData, owner) {
    //     var keys = ["fullName", "emailAddresses", "phoneNumbers","dob", "age", "indicators"];
    //     var result = personData.map(o => Object.assign(...keys.map(k => ({ [k]: o[k] }))));
    //     console.log(result);
    //     const convertedData = flattenObject(result);
    //     console.log("Converted: ", convertedData);
    //     const dataToConvert = {
    //         data: convertedData,
    //         filename: owner + " Owner Report",
    //         delimiter: ',',
    //         headers: ["OwnerName", "OwnerEmail1", "OwnerEmail2", 'OwnerPhone1','OwnerPhone2','OwnerPhone3','OwnerPhone4','OwnerPhone5','OwnerPhone6',"DOB", "Bankruptcy"]
    //     }
    //     csvDownload(dataToConvert)
    // }

  return (
    <Container className='file-data mb-5'>
        <Row className='justify-content-center'>
            <Col lg={3} className='file-info mt-5 mb-5'>
                <h2>View Relatable</h2>
            </Col>
        </Row>
        <Row className='justify-content-center'>
            <Col lg={12} className='p-4 border-1'> 
            { data && 
                
                <table className='table tbl-view'>
                    <thead>
                    <tr className='th'>
                        <th className='head' scope="col">Owner Name</th>
                        <th className='head' scope="col">Owner Email 1</th>
                        <th className='head' scope="col">Owner Email 2</th>
                        <th className='head' scope="col">Owner Current Phone 1</th>
                        <th className='head' scope="col">Owner Current Phone 2</th>
                        <th className='head' scope="col">Owner Phone 3</th>
                        <th className='head' scope="col">Owner Phone 4</th>
                        <th className='head' scope="col">Owner Phone 5</th>
                        <th className='head' scope="col">Owner Phone 6</th>
                        <th className='head' scope="col">DOB</th>
                        <th className='head' scope="col">Bankrupcy</th>
                        <th className='head' scope="col">Deceased</th>
                    </tr>
                    </thead>
                    <tbody>
                    { data.map((item) =>
                        <tr>
                            <td>{item.OwnerName}</td>
                            <td>{item.OwnerEmail1}</td>
                            <td>{item.OwnerEmail2}</td>
                            <td>{item.OwnerCurrentPhone1}</td>
                            <td>{item.OwnerCurrentPhone2}</td>
                            <td>{item.OwnerPhone3}</td>
                            <td>{item.OwnerPhone4}</td>
                            <td>{item.OwnerPhone5}</td>
                            <td>{item.OwnerPhone6}</td>
                            <td>{item['DOB']}</td>
                            <td>{item.Bankruptcy}</td>
                            <td>{item.Deceased}</td>
                            {/* <td>{item.addresses.split(";").map((addr) => 
                                <p>{addr}</p>
                            )}</td>
                            <td>{item.phoneNumbers.split(",").map((phone) => 
                                <p>{phone}</p>
                            )}</td>
                            <td>{item.emailAddresses.split(",").map((email) => 
                                <p>{email}</p>
                            )}</td> */}
                        </tr>
                    )}
                    <tr>
                        <td colSpan={11}> <center><Button variant='flat' className='btn-skyblue mt-2 mb-2' onClick={()=>handleDownload(id)} ><MdArrowDownward /> Export</Button></center></td>
                    </tr>
                    </tbody>
                    
                </table>
                
            }

            
            </Col>
        </Row>
        
    </Container>
  )
}


// const ProfileBox = ({obj}) => {
//     return(
//         <><table className='table'>
//             <thead>
//             <tr className='th'>
//                 <th className='head' scope="col">Name</th>
//                 <th className='head' scope="col">Address</th>
//                 <th className='head' scope="col">Phone Numbers</th>
//                 <th className='head' scope="col">Email Addresses</th>
//                 <th className='head' scope="col">Assets</th>
//                 <th className='head' scope="col">Businesses</th>
//             </tr>
//             </thead>
//             <tbody>
//                 <td>{obj.name}</td>
//                 <td>{obj.address}</td>
//                 <td>{obj.name}</td>
//                 <td>{obj.name}</td>
//                 <td>{obj.name}</td>
//                 <td>{obj.name}</td>
//                 <td>{obj.name}</td>
//             </tbody>
            
//         </table></>
//     )
// }
import React, { useEffect, useState } from 'react'
import { Button, Col, Container, Row } from 'react-bootstrap'
import { FilePond, registerPlugin } from 'react-filepond'
import { BiCloudUpload } from "react-icons/bi";
import CloudImg from '../assets/img/cloud-add.png'
import FilePondPluginImagePreview from 'filepond-plugin-image-preview';
import { uploadFile } from '../api/api';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { resetSession } from '../store/user/authSlice';
import HistoryComp from '../components/HistoryComp';

export const Home = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const authStore = useSelector(state => state.auth)
    const [file, setFile] = useState();

    useEffect(()=>{
        if(file){
            uploadFile(file, authStore.token).then((resData) => {
                console.log('RES: ',resData);
                navigate(`/file-data/${resData.data?._id}`);
            }).catch((err)=>{
                if(err.response.status == 401){
                    localStorage.removeItem('auth')
                    dispatch(resetSession())
                    navigate('/')
                };
                
            })
        }
    },[file])
    
    return (
        <>
            <Container>
                <Row className='h-100 d-flex justify-content-center pt-5'>
                    <Col lg={6} className='text-center'>
                        <h2>Upload Excel File</h2>
                        <FilePond 
                            className='mt-4'
                            allowMultiple={false}
                            labelIdle={ `<img src='${CloudImg}' />
                            <h3 style='font-size:20px; font-weight: 600;' >Choose a file or drag & drop it here </h3>or <br/> <span class="filepond--label-action">Browse</span>`}
                            onaddfile={(err,file) =>{
                              setFile(file.file);
                            //   dispatch(initFile(''))
                            }}
                            onremovefile={(err,file) =>{
                            //   setDocFile('');
                            //   dispatch(initFile(''))
                            //   setScan(false)
                            }}
                        />
                    </Col>
                </Row>
                <HistoryComp />
               
            </Container>
        </>
    )
}

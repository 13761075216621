import React, { useRef, useState } from "react";

// Create an ElementMaker component
function EventEdit(props) {
    const [showInputEle, setShowInputEle] = useState(false);
    const [editName, setEditName] = useState("");
    const inputRef = useRef();

    const handleDoubleClick = (item) => {
        setEditName(item.name.substring(0, item.name.lastIndexOf('.') || item.name))
        setShowInputEle(true);
    }
    const handleBlur = (item) => {
        if(item.name.substring(0, item.name.lastIndexOf('.') || item.name) != editName){
            console.log(editName, item)
            setShowInputEle(false);
            props.handleUpdate(editName);
        }else{
            setShowInputEle(false);
        }
    }

    const keypressHandler = event => {
        if (event.key === "Enter") {
          inputRef.current.blur();
        }
    };
  return (
    <span>
      {
        // Use JavaScript's ternary operator to specify <span>'s inner content
        showInputEle ? (
          <input
            type="text"
            value={editName}
            onChange={(e)=>setEditName(e.currentTarget.value)}
            onBlur={()=>handleBlur(props.item)}
            ref={inputRef}
            onKeyDown={event => keypressHandler(event)}
            autoFocus
          />
        ) : (
          <span
            onDoubleClick={()=>handleDoubleClick(props.item)}
            
          >
            {props.item.name}
          </span>
        )
      }
    </span>
  );
}

export default EventEdit;
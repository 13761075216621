import React, { useEffect } from 'react'
import { Container } from 'react-bootstrap'
import Logo from '../logo.png'
import { useSelector } from 'react-redux'
import { Link, useNavigate } from 'react-router-dom'
import { FaArrowAltCircleLeft } from "react-icons/fa";


const Header = () => {
  const navigate = useNavigate();
  const authStore = useSelector( state => state.auth);

 
  useEffect(()=>{
      if(!authStore.token) navigate('/')
  },[authStore.token])

  return (
    <div className='header-top text-center'>
        <Container>
          <Link className="back-btn" to={`/`}><FaArrowAltCircleLeft /></Link>
          <Link to={`/`}><img src={Logo} height={45}/> &nbsp; PPL SHARK</Link>
        </Container>
        
    </div>
  )
}

export default Header
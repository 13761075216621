import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { deleteExcelFile, getExcelFiles, updateExcelFile } from '../api/api';
import { resetSession } from '../store/user/authSlice';
import { Button, Col, Row } from 'react-bootstrap';
import CustomPagination from './CustomPagination';
import { MdOutlineRemoveRedEye } from 'react-icons/md';
import { IoTrashBinOutline } from "react-icons/io5";

import moment from 'moment';
import { Bounce, toast } from 'react-toastify';
import EventEdit from './EventEdit';

const HistoryComp = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const authStore = useSelector(state => state.auth)
    const [data,setData] = useState([]);
    const [from, setFrom] = useState(0);
    const [limit, setLimit] = useState(10)
    const [page, setPage] = useState(1);
    const [counts, setCounts] = useState(0);
    const [showInputFile, setShowInputFile] = useState(false)

    const fetchData = () => {
        getExcelFiles(page,limit, authStore.token).then((resData)=>{
            console.log(resData)
            setCounts(resData.data.docCount)
            setData(resData.data.result)
        }).catch((err)=>{
            if(err.response.status == 401){
                localStorage.removeItem('auth')
                dispatch(resetSession())
                navigate('/')
            };
        })
    }

    const handleView = (id) => {
        navigate(`/file-data/${id}`);
    }

    const handleRemove = (id) =>{
        if(!authStore?.token) return;
        if(window.confirm("Are you sure you want to remove this file?")){
            deleteExcelFile(authStore?.token, id).then((resData)=>{
                // console.log("RES: ",resData);
                fetchData()
                toast.success("File Deleted!", {
                    autoClose: true,
                    theme: "colored",
                    transition: Bounce,
                });
            }).catch((err)=>{
                if(err.response.status == 401){
                    localStorage.removeItem('auth')
                    dispatch(resetSession())
                    navigate('/')
                };
                toast.error("Something went wrong!", {
                    autoClose: true,
                    theme: "colored",
                    transition: Bounce,
                });
            })
        }


    }

    const handleUpdate = (item, filename, index) =>{
        console.log(index, filename, item)
        const updateData = data;
        const updatedName = `${filename}.${item.name.split('.').pop()}`;
        updateData[index].name = updatedName;
        setData(updateData);

        updateExcelFile(authStore?.token, item._id, updatedName).then(()=>{
            console.log("Updated")
        }).catch((err)=>{
            if(err.response.status == 401){
                localStorage.removeItem('auth')
                dispatch(resetSession())
                navigate('/')
            };
            toast.error("Something went wrong!", {
                autoClose: true,
                theme: "colored",
                transition: Bounce,
            });
        })

        if(!authStore?.token) return;

    }

    useEffect(()=>{
        if(authStore?.token){
            fetchData()
        }
    },[page, authStore?.token])


  return (
    <Row>
        <Col lg={12} className='mt-4 table-head-action'>
        </Col>
        <hr className='mt-3 mb-3'/>
        <Col lg={12} className='p-0'>
            <table class="table">
                <thead>
                <tr className='th'>
                    <th className='head' scope="col">#</th>
                    <th className='head' scope="col">File</th>
                    <th className='head' scope="col">Records</th>
                    <th className='head' scope="col">Upload Date</th>
                    <th className='head' scope="col">Action</th>
                </tr>
                </thead>
                <tbody>
                    {
                        data && data.map( (item, index) => 
                        <tr key={index}>
                            <th scope="row">{(index+1)+(page * limit) - limit}</th>
                            <td><EventEdit item={item} handleUpdate={(valueName)=>handleUpdate(item, valueName, index)} /> </td>
                            <td>{item.records}</td>
                            <td>{ moment(item.createdAt).format('YYYY-MM-DD HH:mm:ss') }</td>
                            <td>
                                <Button  onClick={()=>handleView(item._id)} variant='flat' className='btn-darkblue me-2'><MdOutlineRemoveRedEye /> View</Button>
                                <Button variant="danger" onClick={()=>handleRemove(item._id)}><IoTrashBinOutline /></Button>
                            </td>
                        </tr>
                        )
                    }
            
                </tbody>
            </table>
        </Col>
        <CustomPagination from={from} limit={limit} counts={counts} page={page} setFrom={(val)=>setFrom(val)} setPage={(val)=>setPage(val)}/>
    </Row> 
  )
}

export default HistoryComp
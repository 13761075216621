import logo from './logo.svg';
import './App.css';
import { Routes, Route} from 'react-router-dom';
import Login from './pages/Login';
import { Routing } from './layout/Routing';
import { useDispatch } from 'react-redux';
import { setAuth } from './store/user/authSlice';
import { useEffect } from 'react';

function App() {
  
  return (
    // <div className="App">
      <Routes>
        <Route path='/' element={<Login />} />
        <Route path='/*' element={<Routing />} />
      </Routes>
    // </div>
  );
}

export default App;

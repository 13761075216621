import React, { useEffect, useState } from 'react'
import { Button, Col, Container, Row } from 'react-bootstrap'
import Logo from '../logo.png'
import { Form } from 'react-bootstrap'
import { login } from '../api/api'
import { useSelector, useDispatch } from 'react-redux'
import { setAuth } from '../store/user/authSlice'
import { useNavigate } from 'react-router-dom'


const Login = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const authStore = useSelector( state => state.auth);
    const [formData, setFromData] = useState();
    const [errMsg, setErrMsg] = useState("");

    

    const handleLogin = () => {
        console.log(formData?.email.length);
        if(!formData?.email.length) return setErrMsg("Email required")
        if(formData?.password.length < 6) return setErrMsg("Password required minmum 6 character")

        login(formData).then((resData) => {
            console.log(resData)
            localStorage.setItem('auth', JSON.stringify(resData.data));
            dispatch(setAuth(resData.data))
            setErrMsg("")
            navigate('/home')
        }).catch((err) => {
            console.log(err)
            setErrMsg(err.response.data.data)
        })
        
    }

    useEffect(()=>{
        if(authStore.token){
            navigate('/home')
        }
    },[authStore.token])

    

  return (
    <Container fluid className='login' >
        <Row className='h-100 d-flex justify-content-center pt-5'>
            <Col lg={3} className='text-center'>
                <div className='site-info mb-5'>
                    <img src={Logo} width={105}/>
                    <h2>PPL SHARK</h2>
                </div>
                <h2 className='mb-4'>Login</h2>
                <div className='login-form'>
                    <Form.Control type="email" placeholder="Email" onChange={(e)=>setFromData({...formData, email: e.currentTarget.value})}/>
                    <Form.Control type="password" placeholder="Password" onChange={(e)=>setFromData({...formData, password: e.currentTarget.value})} />
                    <Button variant="flat" className='mt-4' onClick={()=>handleLogin()}>Login</Button>
                </div>
                <div className='error'>
                    {errMsg}
                </div>
            </Col>
        </Row>
    </Container>
  )
}

export default Login
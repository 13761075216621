import React from 'react'
import { Routes, Route} from 'react-router-dom';
import { Home } from '../pages/Home';
import Header from './Header';
import { FileData } from '../pages/FileData';
import { Details } from '../pages/Details';
import History from '../pages/History';

export const Routing = () => {
  return (
    <>
        <Header />
        <Routes>
            <Route path='/home' element={<Home />} />
            <Route path='/file-data/:id' element={<FileData />} />
            <Route path='/detail/:id' element={<Details />} />
            <Route path='/history' element={<History />} />
        </Routes>
    </>
  )
}

import csvDownload from "json-to-csv-export";
import moment from "moment";

export const flattenObject = (obj) =>{
    const emailNameArr = ['OwnerEmail1','OwnerEmail2'];
    const phoneNameArr = ['OwnerPhone3','OwnerPhone4','OwnerPhone5','OwnerPhone6'];
    const currentPhoneNameArr = ['OwnerCurrentPhone1','OwnerCurrentPhone2'];
    // console.log("Obj",obj)
    for (const key in obj) {
        if(key == 'fullName') {
            obj['OwnerName'] = obj[key];
            delete obj[key];
        }
        if(key == 'indicators') {
            obj['Bankruptcy'] = obj[key].hasBankruptcyRecords;
            delete obj[key];
        }
        if(key == 'dob'){
            if(obj[key]){
                obj["DOB"] =  obj[key];
            }else{
                obj["DOB"] = obj["age"]? obj["age"] : null;
            }
            delete obj["age"];
            delete obj[key];
        }
        if(key == 'deathRecords'){
            obj["Deceased"] = obj[key].isDeceased? "YES" : "NO";
            delete obj[key];
        }
        if (Array.isArray(obj[key])) {
            // Convert inner array to string
            //obj[key] = JSON.stringify(obj[key]);
            if (key == 'addresses') {
                // console.log("Addr: ",obj[key][0].fullAddress)
                obj[key] = obj[key][0].fullAddress;
            }else if(key == 'phoneNumbers') {
                
                console.log("NUMBERS: ", obj[key]);
                let currentWirelessCount = 0;
                let currentLandlineCount = 0;
                let extraPhoneCount = 0;
                let totalCount = 0;
                const currentPhones = [];
                obj["OwnerCurrentPhone1"] = '';
                obj["OwnerCurrentPhone2"] = '';
                obj["OwnerPhone3"] = '';
                obj["OwnerPhone4"] = '';
                obj["OwnerPhone5"] = '';
                obj["OwnerPhone6"] = '';
                for(const [i, item] of obj[key].entries()){
                    console.log("ITEM: ", item)
                    if(item.phoneType == "Wireless" && currentWirelessCount == 0){
                        console.log("CURRENT WIRE: ", totalCount, item?.phoneNumber)
                        obj[currentPhoneNameArr[totalCount]] = `+1 ${item?.phoneNumber}`;
                        totalCount++;
                        currentWirelessCount++;
                    }else if(item.phoneType == "LandLine/Services" && currentLandlineCount == 0){
                        console.log("CURRENT LAND: ", totalCount, item?.phoneNumber)
                        obj[currentPhoneNameArr[totalCount]] = `+1 ${item?.phoneNumber}`;
                        totalCount++;
                        currentLandlineCount++;
                    }else if(extraPhoneCount < 4){
                        console.log("NORMAL : ", extraPhoneCount, item?.phoneNumber)
                        obj[phoneNameArr[extraPhoneCount]] = `+1 ${item?.phoneNumber}`;
                        extraPhoneCount++;
                    }
                }



                //obj[key] = obj[key].map(obj => obj['phoneNumber']).join(', ');
                // for (const [i, value] of phoneNameArr.entries()) {
                //     if(currentPhones.includes())
                //     if(phoneType == "wireless" && currentWirelessCount == 0){
                //         obj[phoneNameArr[i]] = obj[key][i] ? `(CC) +1 ${obj[key][i]?.phoneNumber}` : '';
                //         currentWirelessCount++;
                //     }
                //     if(phoneType == "LandLine/Services" && currentLandlineCount == 0){
                //         obj[phoneNameArr[i]] = obj[key][i] ? `(CC) +1 ${obj[key][i]?.phoneNumber}` : '';
                //         currentLandlineCount++;
                //     }
                    
                // }
                delete obj[key];
            } else if (key == 'emailAddresses') {
                for (const [i, value] of emailNameArr.entries()) {
                    obj[emailNameArr[i]] = obj[key][i] ? obj[key][i]?.emailAddress  : '';
                }
                delete obj[key];
            }
        } else if (typeof obj[key] === 'object' && obj[key] !== null) {
            // Recursively check nested object
            obj[key] = flattenObject(obj[key]);
        }
    }

    
    return obj;
}

const calculateBirthdate = (age) => {
    const currentDate = moment();
    const birthdate = currentDate.subtract(age, 'years');
  
    return birthdate.format('YYYY');
  }
  

export const flattenObjectOnly = (obj) =>{
   
    console.log(obj)
    for (const key in obj) {

        if (Array.isArray(obj[key])) {
            // Convert inner array to string
            //obj[key] = JSON.stringify(obj[key]);
            if (key == 'addresses') {
                // console.log("Address",obj[key])
                obj['OwnerMailingAddress'] = obj[key][0]['fullAddress'];
            } else if (key == 'phoneNumbers') {
                obj[key] = obj[key].map(obj => obj['phoneNumber']);
            } else if (key == 'emailAddresses') {
                obj[key] = obj[key].map(obj => obj['emailAddress']);
            }
        } else if (typeof obj[key] === 'object' && obj[key] !== null) {
            // Recursively check nested object
            obj[key] = flattenObject(obj[key]);
        }
    }
    return obj;
}

export function dataDownload(personData, owner) {
    var keys = ['addresses',"fullName", "emailAddresses", "phoneNumbers","dob", "age","indicators", "deathRecords"];
    var result = personData.map(o => Object.assign(...keys.map(k => ({ [k]: o[k] }))));
    console.log(result);
    const convertedData = flattenObject(result);
    console.log("Converted: ", convertedData);
    const dataToConvert = {
        data: convertedData,
        filename: owner + " Owner Report",
        delimiter: ',',
        headers: ['OwnerMailingAddress',"OwnerName", "OwnerEmail1", "OwnerEmail2", 'OwnerCurrentPhone1','OwnerCurrentPhone2','OwnerPhone3','OwnerPhone4','OwnerPhone5','OwnerPhone6',"DOB", "Bankruptcy", "Deceased"]
    }
    csvDownload(dataToConvert)
}

export function dataDownloadAll(excelRecords, owner) {
    const finalArry = [];
    let rowCount = 0;
    const personStructKeys = {'OwnerMailingAddress': "","OwnerName": "", "OwnerEmail1": "", "OwnerEmail2": "", 'OwnerCurrentPhone1': "",'OwnerCurrentPhone2': "",'OwnerPhone3': "",'OwnerPhone4': "",'OwnerPhone5': "",'OwnerPhone6':"","DOB": "", "Bankruptcy": ""}
    for(var excelRecord of excelRecords ){
        const mainStructObj = {
            "FullAddress": "",
            "Address": "",
            "City": "",
            "State": "",
            "Zipcode": "",
            "GrossSF": "",
            "YearBuilt": "",
            "AssessedValue": "",
            "OwnerNameForMailing": "",
        }

        let mainObj = {};
        mainObj.FullAddress = `${excelRecord.address}, ${excelRecord.city}, ${excelRecord.state} - ${excelRecord.zip_code}`;
        mainObj.Address = excelRecord.address;
        mainObj.City = excelRecord.city;
        mainObj.State = excelRecord.state;
        mainObj.Zipcode = excelRecord.zip_code;
        mainObj.GrossSF = excelRecord.gross_sf;
        mainObj.YearBuilt = excelRecord.year_built;
        mainObj.AssessedValue = excelRecord.assessed_value;
        mainObj.OwnerNameForMailing = excelRecord.owner_name_for_mailing;
        // mainObj.OwnerMailingAddress = excelRecord.owner_mailing_address;


        let personData = excelRecord?.galaxySearchResponse[0]?.personSearchResponse;
        if(personData.length > 0){
            var keys = ['addresses', "fullName", "emailAddresses", "phoneNumbers","dob", "age", "indicators", "deathRecords"];
            var result = personData?.map(o => Object.assign(...keys.map(k => ({ [k]: o[k] }))));
            const convertedData = flattenObject(result);
            console.log("FINAL: ",convertedData);
            for(const [i, value] of convertedData.entries()){
                // if(i == 0){
                //     let mergedRow = {...mainObj, ...value};
                //     finalArry.push(mergedRow)
                // }else{
                //     console.log("Main Struct: ", mainStructObj)
                //     let subRow = mainStructObj;
                //     let mergedRow = {...subRow, ...value};
                //     finalArry.push(mergedRow)
                // }

                     let mergedRow = {...mainObj, ...value};
                    finalArry.push(mergedRow)
                
            }
        }else{
            let mergedRow = {...mainObj, ...personStructKeys};
            finalArry.push(mergedRow)
        }
       
    }

    // console.log("Final : ", finalArry);
    const dataToConvert = {
        data: finalArry,
        filename: owner + " Owner Report",
        delimiter: ',',
        headers: [
            "FullAddress",
            "Address",
            "City",
            "State",
            "Zipcode",
            "GrossSF",
            "YearBuilt",
            "AssessedValue",
            "OwnerNameForMailing",
            "OwnerMailingAddress",
            "OwnerName", "OwnerEmail1", "OwnerEmail2", 'OwnerCurrentPhone1','OwnerCurrentPhone2','OwnerPhone3','OwnerPhone4','OwnerPhone5','OwnerPhone6',"DOB", "Bankruptcy", "Deceased"]
    }
    const csvResult = csvDownload(dataToConvert)
    // alert(csvResult);

    
    // "City",
    // "State",
    // "Zipcode",
}
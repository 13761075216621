import React, { useEffect } from 'react'
import { Col } from 'react-bootstrap'
import { PaginationControl } from 'react-bootstrap-pagination-control';

const CustomPagination = ({limit, counts, page, setPage}) => {
  
  return (
    <>
      
      <Col lg={12} className='table-pagination mb-5'>
        
        <PaginationControl
          page={page}
          between={3}
          total={counts}
          limit={limit}
          changePage={(page) => {
            setPage(page)
          }}
          ellipsis={1}
        />
      </Col>

    </>


  )
}

export default CustomPagination
//@ts-nocheck
import { createSlice } from '@reduxjs/toolkit'

const user = localStorage.getItem('auth')? JSON.parse( localStorage.getItem('auth') ).user : Object;
const token = localStorage.getItem('auth')? JSON.parse( localStorage.getItem('auth') ).token : null;

export const authSlice = createSlice({
  name: 'auth',
  initialState: {
    user: user,
    token: token
  },
  reducers: {
    setAuth: (state, action) => {
      state.user = action.payload.user;
      state.token = action.payload.token;
    },
    resetSession: (state, action) => {
      state.user = Object;
      state.token = null;
    }
  },
})



// Action creators are generated for each case reducer function
export const {setAuth, resetSession } = authSlice.actions

export default authSlice.reducer
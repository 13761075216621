import React, { useEffect, useState } from 'react'
import { getExcelFileById } from '../api/api'

const FetchSearchResult = ({id,token,setIsDisable}) => {
    const [data, setData] = useState(null);
    const [intervalId, setIntervalId] = useState()

    const fetchData = (token, id, interval) => {
        getExcelFileById(token, id).then((resData)=>{
            if(resData.data.records == resData.data.fetchRecords){
                setData(null)
                setIsDisable(false)
                clearInterval(interval)

            }else{
                setData(resData.data)
            }
        }).catch((err)=>{
            
            clearInterval(interval)
        })
    }

    useEffect(()=>{
        const interval = setInterval(()=> {
            fetchData(token,id, interval);
            // getExcelFileById(token, id).then((resData)=>{
            //     if(resData.data.records == resData.data.fetchRecords){
            //         setData(null)
            //         setIsDisable(false)
            //         clearInterval(interval)

            //     }else{
            //         setData(resData.data)
            //     }
            // }).catch((err)=>{
            //     clearInterval(interval)
            // })
        }, 2000);
        return () => {
            console.log(`clearing interval`);
            clearInterval(interval);
        };
    },[id,token])
  return (
    <>
        {
            data && <h6 className='me-5 mb-0'> Fetching search result... ({data?.fetchRecords} of {data?.records})</h6>
        }
        
        
    </>
  )
}

export default FetchSearchResult
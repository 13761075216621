import axios from "axios";
// import { store } from '../store';
// const { dispatch } = store;


export const login = async (reqData) => {
    return new Promise((resolve, reject) => {
        axios.post(`${process.env.REACT_APP_API_BASEURL}/auth/login`, reqData).then((resData) => {
            resolve(resData.data)
        }).catch((err)=>{
            reject(err);
        });
    })
}

export const uploadFile = async (excel, token) => {
    return new Promise((resolve, reject) => {
        axios.post(`${process.env.REACT_APP_API_BASEURL}/extract/upload`, {
            file: excel
        },{
            headers: {
                "Content-Type": "multipart/form-data",
                "x-rapidapi-host": "file-upload8.p.rapidapi.com",
                "x-rapidapi-key": "your-rapidapi-key-here",
                "x-access-token" : token 
            },
        }).then((resData) => {
            resolve(resData.data)
        }).catch((err)=>{
            reject(err);
        });
    })
}

export const getExcelRecord = (page,limit, filter, token, id) => {
    // const perPage = 10;
    return new Promise((resolve, reject) => {
        axios.get(`${process.env.REACT_APP_API_BASEURL}/extract/get_excel_data_by_id/${id}?page=${page}&limit=${limit}&filter=${filter}`, { headers: { "x-access-token" : token }}).then((resData) => {
            resolve(resData.data)
        }).catch((err)=>{
            reject(err);
        });
    })
}

export const getExcelFileById = (token, id) => {
    // const perPage = 10;
    return new Promise((resolve, reject) => {
        axios.get(`${process.env.REACT_APP_API_BASEURL}/extract/get_excel_file_by_id/${id}`, { headers: { "x-access-token" : token }}).then((resData) => {
            resolve(resData.data)
        }).catch((err)=>{
            reject(err);
        });
    })
}


export const getExcelFiles = (page,limit, token) => {
    // const perPage = 10;
    return new Promise((resolve, reject) => {
        axios.get(`${process.env.REACT_APP_API_BASEURL}/extract/get_excel_files?page=${page}&limit=${limit}`, { headers: { "x-access-token" : token }}).then((resData) => {
            resolve(resData.data)
        }).catch((err)=>{
            reject(err);
        });
    })
}


export const deleteExcelFile = (token, id) => {
    // const perPage = 10;
    return new Promise((resolve, reject) => {
        axios.delete(`${process.env.REACT_APP_API_BASEURL}/extract/delete_excel_file/${id}`, { headers: { "x-access-token" : token }}).then((resData) => {
            resolve(resData.data)
        }).catch((err)=>{
            reject(err);
        });
    })
}


export const updateExcelFile = (token, id, filename) => {
    // const perPage = 10;
    return new Promise((resolve, reject) => {
        axios.put(`${process.env.REACT_APP_API_BASEURL}/extract/update_excel_file/${id}`, {filename: filename}, { headers: { "x-access-token" : token }}).then((resData) => {
            resolve(resData.data)
        }).catch((err)=>{
            reject(err);
        });
    })
}


export const downloadRecord = (id, token) => {
    // const perPage = 10;
    return new Promise((resolve, reject) => {
        axios.get(`${process.env.REACT_APP_API_BASEURL}/extract/get_galaxy_data_from_excel_id?id=${id}`, { headers: { "x-access-token" : token }}).then((resData) => {
            resolve(resData.data)
        }).catch((err)=>{
            reject(err);
        });
    })
}


export const downloadAllRecord = (page,limit, filter,token, id) => {
    // const perPage = 10;
    return new Promise((resolve, reject) => {
        axios.get(`${process.env.REACT_APP_API_BASEURL}/extract/get_all_galaxy_data_excel/${id}?page=${page}&limit=${limit}&filter=${filter}`, { headers: { "x-access-token" : token }}).then((resData) => {
            resolve(resData.data)
        }).catch((err)=>{
            reject(err);
        });
    })
}

